<template>
  <div
    class="container-xs"
    style="display: flex; flex-direction: column; height: 100%"
  >
    <AppHeader back-to="/profile/edit" />
    <AppDataExclaimer style="margin-top: 24px" />
    <div style="display: grid">
      <XLabel for="date-of-birth" tag="label" style="margin-top: 2rem">{{
        $t('form.dob')
      }}</XLabel>
      <FormInput
        id="date-of-birth"
        ref="dateOfBirth"
        :value="displayDate"
        required
        @focus="e => $refs.datePicker.open(e)"
      />
      <datetime
        ref="datePicker"
        v-model="forms.user.dateOfBirth"
        :max-datetime="dp.max"
        :min-datetime="dp.min"
        auto
        value-zone="UTC"
        zone="UTC"
      />
    </div>
    <x-button
      id="save"
      type="large"
      style="margin-top: auto"
      :disabled="!forms.vget.user.form"
      :is-loading="isLoading"
      @click="save"
    >
      {{ $t('form.save') }}
    </x-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppDataExclaimer from '../../components-rf/AppDataExclaimer.vue'
import AppHeader from '../../components-rf/AppHeader.vue'
import XLabel from '../../components/common/global/XLabel.vue'
import FormInput from '../../components/utils/FormInput.vue'
import { createDatepicker } from '../../other/utils/datepickerUtil'

export default {
  name: 'EditProfileDateOfBirth',
  components: { FormInput, XLabel, AppHeader, AppDataExclaimer },
  created() {
    this.$watch(
      () => this.forms.user.dateOfBirth,
      value => this.forms.vset.user.dateOfBirth(!!value),
      { immediate: true },
    )
    this.forms.user.dateOfBirth = this.dp.getValue(this.user.dateOfBirth)
  },
  data() {
    return {
      dp: createDatepicker({ min: 100, max: 18, initial: 30 }),
      forms: { user: { dateOfBirth: '' } },
      isLoading: false,
    }
  },
  computed: {
    ...mapState('UserModule', ['user']),
    displayDate: vm => vm.dp.formatValue(vm.forms.user.dateOfBirth),
  },
  methods: {
    save() {
      this.isLoading = true
      this.$store
        .dispatch('UserModule/updateUser', {
          dateOfBirth: this.forms.user.dateOfBirth,
        })
        .then(() => this.$router.push('/profile/edit'))
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>
