<template>
  <div class="app-data-exclaimer">
    <IconExclaimer />
    <p>As listed on passport or government-issued photo ID</p>
  </div>
</template>

<script>
import IconExclaimer from './icons/common/IconExclaimer.vue'
export default {
  name: 'AppDataExclaimer',
  components: { IconExclaimer },
}
</script>

<style scoped lang="scss">
.app-data-exclaimer {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  align-items: center;
  padding: 8px 16px;
  border-radius: 22px;
  background: #f42f0420;
  font-family: DDin;
  font-size: 15px;
  line-height: 1.2;
}
</style>
