import { date } from './helpers/date'

const getDateBeforeNYears = years => {
  if (years === null) return
  if (!years) return new Date(Date.now()).toISOString()
  const today = new Date(Date.now()).toISOString().split('-')
  today[0] = String(Number(today[0]) - years)
  return today.join('-')
}

export const createDatepicker = (
  props = {
    min: undefined,
    max: undefined,
    initial: undefined,
  },
) => {
  const initial = props.initial ? getDateBeforeNYears(props.initial) : ''
  return {
    min: getDateBeforeNYears(props.min),
    max: getDateBeforeNYears(props.max),
    initial: props.initial ? getDateBeforeNYears(props.initial) : '',
    getValue: inputValue => {
      const value = inputValue || initial
      if (value) return value
    },
    formatValue: inputValue =>
      inputValue ? date.formatUTC('-m2/-d2/-y', inputValue) : '',
    formatTZ: value => (!value ? '' : value.split('T')[0] + 'T00:00:00Z'),
  }
}
